import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Button,
  IconButton,
  Checkbox,
  Box,
  Radio,
} from "../../../components";
import QuestionList from "../../Settings/ScoringCard/QuestionList";
import "./FunnelsSettings.scss";
import translate from "../texts";
import ColumnsControls from "./ColumnsControls/ColumnsControls";
import UserAssignmentsContainer from "./UserAssignments/UserAssignmentsContainer";
import InputSourcesContainer from "./InputSources/InputSourcesContainer";
import { connect } from "react-redux";

class FunnelsSettings extends PureComponent {
  state = {
    checkAll: this.props.hiddenColumns,
    scoringOptions: false,
  };

  funnelTitleRef = React.createRef();

  componentDidMount() {
    if (!this.props.isEditing) {
      this.funnelTitleRef.current.querySelector(".input").focus();
    }
  }

  checkAll = () => {
    const fieldChangeFunction = this.props.handleFieldChange("hiddenColumns");
    if (this.state.checkAll.length === 0) {
      this.setState({ checkAll: this.props.fields.map((field) => field.id) });
      fieldChangeFunction(
        { target: { value: null } },
        this.props.fields.map((field) => field.id)
      );
      return;
    }
    this.setState({ checkAll: [] });
    fieldChangeFunction({ target: { value: null } }, []);
  };

  render() {
    const {
      saveFunnelSettings,
      closeFunnelSettings,
      inputSources,
      name,
      questions,
      hiddenColumns,
      assignedUsers,
      operationBetweenInputSources,
      handleFieldChange,
      fields,
      users,
      groups,
      isEditing,
      onDelete,
      onCopy,
      isEdited,
      programId,
      showAverageAndPreviousScore,
      excludedSubmissions,
      is_final_funnel,
      start_date,
      end_date,
      mobileVisible,
    } = this.props;

    const { isFetching } = this.props;
    const handleOverlayClick = (e) => {
      if (e.target.className.includes("funnels_settings")) {
        // eslint-disable-next-line no-alert
        if (
          !isEdited ||
          (isEdited &&
            window.confirm("Are you sure you want to close funnel settings?"))
        ) {
          this.props.closeFunnelSettings();
        }
      }
    };

    return (
      <div
        className="funnels_settings z-40"
        key={isEditing ? "is-editing" : "new-funnel"}
        onClick={handleOverlayClick}
        onKeyPress={() => {}}
        role="presentation"
      >
        <div className="funnels_settings_form_container">
          <div className="control" ref={this.funnelTitleRef}>
            <div className="label-with-line">
              <span>{translate("funnel_name")}</span>
            </div>
            <Input
              name="name"
              value={name}
              onChange={handleFieldChange("name")}
            />
          </div>

          <div className="">
            <Checkbox
              className="field-checkbox check-all-checkbox"
              checked={is_final_funnel}
              name="is_final_funnel"
              id="is_final_funnel"
              onChange={handleFieldChange("is_final_funnel")}
              label={translate("is_final_funnel")}
              value={"true"}
            />
          </div>

          <div className="">
            <Checkbox
              className="field-checkbox check-all-checkbox"
              checked={mobileVisible}
              name="mobileVisible"
              id="mobileVisible"
              onChange={handleFieldChange("mobileVisible")}
              label={translate("mobileVisible")}
              value={"true"}
            />
          </div>

          <div className="mt-4">
            <div className="label-with-line">
              <span>{translate("funnel_dates")}</span>
            </div>

            <div className="">
              <div className="flex gap-3">
                <div className="grow">
                  <Input
                    inputId={"start_date"}
                    label={translate("start_date")}
                    placeholder={translate("start_date")}
                    inputName="start_date"
                    inputType="datePicker"
                    value={start_date}
                    onChange={handleFieldChange("start_date")}
                  />
                </div>
                <div className="grow">
                  <Input
                    inputId={"end_date"}
                    label={translate("end_date")}
                    placeholder={translate("end_date")}
                    inputName="end_date"
                    inputType="datePicker"
                    value={end_date}
                    onChange={handleFieldChange("end_date")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="control column">
              <div className="label-with-line">
                <span>{translate("funnel_input_sources")}</span>
              </div>
              <InputSourcesContainer
                value={inputSources}
                operationBetweenInputSources={operationBetweenInputSources}
                onChange={handleFieldChange("inputSources")}
                onInputSourcesOperationChange={handleFieldChange(
                  "operationBetweenInputSources"
                )}
              />
            </div>
            {!is_final_funnel && (
              <div className="control column">
                <div className="label-with-line">
                  <span>{translate("funnel_scoring_cards")}</span>
                </div>
                <QuestionList
                  value={questions}
                  onChange={handleFieldChange("questions")}
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <Checkbox
              className="field-checkbox check-all-checkbox"
              checked={excludedSubmissions}
              name="excluded_submissions"
              id="excluded_submissions"
              onChange={handleFieldChange("excluded_submissions")}
              label={translate("submissions not included in sources")}
              value={"true"}
            />
          </div>

          <div className="columns">
            <div className="control column">
              <div className="label-with-line">
                <span>{translate("funnel_hide_show_columns")}</span>
              </div>
              <Box className="fields">
                <Checkbox
                  className="field-checkbox check-all-checkbox"
                  checked={this.state.checkAll.length === 0}
                  name="checkAll"
                  id="checkAllFieldsCheckBox"
                  onChange={() => this.checkAll()}
                  label={translate("select_all")}
                  value="checkAll"
                  hideIcon
                />
                <ColumnsControls
                  fields={fields}
                  value={this.state.checkAll}
                  onChange={handleFieldChange("hiddenColumns")}
                  onOrderChange={handleFieldChange("fieldsOrder")}
                  checkAll={this.state.checkAll}
                />
              </Box>
            </div>
            <div className="control column">
              <div className="label-with-line">
                <span>{translate("funnel_assign")}</span>
              </div>
              <UserAssignmentsContainer
                value={assignedUsers}
                users={users}
                groups={groups}
                onChange={handleFieldChange("assignedUsers")}
                programId={programId}
              />
            </div>
          </div>
          <div className="">
            <p>{translate("Scoring Options")}</p>
            <div className="w-full flex flex-col gap-3 my-3">
              <Radio
                wide
                name={"showAverageAndPreviousScore"}
                value={"hide_both"}
                checked={showAverageAndPreviousScore === "hide_both"}
                onChange={handleFieldChange("showAverageAndPreviousScore")}
              />
              <Radio
                wide
                name={"showAverageAndPreviousScore"}
                value={"show_both"}
                checked={showAverageAndPreviousScore === "show_both"}
                onChange={handleFieldChange("showAverageAndPreviousScore")}
              />
              <Radio
                wide
                name={"showAverageAndPreviousScore"}
                value={"show_both_with_answers"}
                checked={
                  showAverageAndPreviousScore === "show_both_with_answers"
                }
                onChange={handleFieldChange("showAverageAndPreviousScore")}
              />
              <Radio
                wide
                name={"showAverageAndPreviousScore"}
                value={"show_both_with_comments"}
                checked={
                  showAverageAndPreviousScore === "show_both_with_comments"
                }
                onChange={handleFieldChange("showAverageAndPreviousScore")}
              />
              <Radio
                wide
                name={"showAverageAndPreviousScore"}
                value={"show_average_only"}
                checked={showAverageAndPreviousScore === "show_average_only"}
                onChange={handleFieldChange("showAverageAndPreviousScore")}
              />
            </div>
          </div>
          <div className="form-buttons gap-4">
            <Button
              id="save-button"
              onClick={() => {
                saveFunnelSettings(
                  name,
                  inputSources,
                  questions,
                  hiddenColumns,
                  assignedUsers,
                  operationBetweenInputSources,
                  showAverageAndPreviousScore
                );
              }}
              content={translate("save")}
              disabled={!name || isFetching}
              loading={isFetching}
            />
            <Button
              id="cancel-button"
              flat
              onClick={closeFunnelSettings}
              content={translate("cancel")}
              disabled={isFetching}
            />
            {isEditing && (
              <IconButton
                iconName="content-copy"
                onClick={onCopy}
                className="copy-button"
                content={translate("clone")}
              />
            )}
            {isEditing && !isFetching && (
              <IconButton
                iconName="delete"
                onClick={onDelete}
                className="delete-button"
                content={translate("delete")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.funnels.isFetching,
});

FunnelsSettings.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  inputSources: PropTypes.array.isRequired,
  operationBetweenInputSources: PropTypes.string.isRequired,
  assignedUsers: PropTypes.array.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  saveFunnelSettings: PropTypes.func.isRequired,
  closeFunnelSettings: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  isEdited: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(FunnelsSettings);
